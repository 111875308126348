export const actions = {
    readAll: 'read_all',
    readSome: 'read_some',
    readSelf: 'read_self',
    read: 'read',
    create: 'create',
    update: 'update',
    delete: 'delete'
};

export const features = {
    user: 'User',
    setting: 'Setting'
};