import { get } from "../utils/api";

const API_URL = process.env.REACT_APP_API_URL;

const GetPeopleList = async (data) => {
  try {
    // const searchValue = data.keyword ? data.keyword : "";
    console.log(data, "datammmm");
    const page = data.page ? data.page : 1;
    const perPage = 10;

    const response = await get(
      `${API_URL}/admin/people?name=${data?.keyword}&page=${page}&per_page=${perPage}`
    );
console.log(response,"res........")
    return response; // Assuming the API returns data in the response
  } catch (error) {
    // Handle any errors that occurred during the API call
    console.error("Error fetching user list:", error);
    // You might want to display an error message to the user or take other actions
    throw error; // Rethrow the error to the calling function if needed
  }
};

// const GetUserById = async (id) => get(`${API_URL}/user/${id}`);

// const CreateUser = async (params) => post(`${API_URL}/admin/user`, params);
// const CreateUser = async (params) => {
//   post(`${API_URL}/admin/add-user`, params);
// };

// const UpdateUser = async (id, params) => patch(`${API_URL}/user/${id}`, params);

// const DeleteUser = async (id) => del(`${API_URL}/user/${id}`);

// const Profile = async () => get(`${API_URL}/user/profile`);

export const PeopleService = {
  // GetUsers,
  // GetUserById,
  // CreateUser,
  // UpdateUser,
  // DeleteUser,
  // Profile,
  GetPeopleList,
};
