import React from "react";

const NoData = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyItems: "center",
          height: "100%",
          alignItems: "center",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: "20px",
        }}
      >
        <div>
        <img src="https://elstar.themenate.net/img/others/img-2.png" alt="No Data Illustration" />
          <h1>No Data Available</h1>
        </div>
      </div>
      <br />
    </>
  );
};

export default NoData;
