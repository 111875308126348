import GeneralSlice from "./slice/GeneralSlice";
import AuthSlice from "./slice/AuthSlice";
import UserSlice from "./slice/UserSlice";
import PeopleSlice from "./slice/PeopleSlice";
import SettingSlice from "./slice/SettingSlice";


export const GeneralActions = GeneralSlice.actions;
export const AuthActions = AuthSlice.actions;
export const UserActions = UserSlice.actions;
export const PeopleActions = PeopleSlice.actions;
export const SettingActions = SettingSlice.actions;