import axios from "axios";
import { apiErrorResponse } from "./utils";
import { toast } from "react-toastify";

export async function get(url, query) {
  const token = localStorage.getItem("helloreddie-token");
  let queryString = "";

  if (query) {
    queryString = `?${new URLSearchParams(query).toString()}`;
  }

  const data = await axios
    .get(url + queryString, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      
      err?.response?.data?.detail && toast.error(err?.response?.data?.detail);
      err?.response?.data?.message && toast.error(err?.response?.data?.message);
      apiErrorResponse(err);
      return err;
    });
  return data;
}

export async function post(url, data) {
  const token = localStorage.getItem("helloreddie-token");

  const formData = new FormData();

  Object.keys(data).map((key) => formData.append(key, data[key]));

  return axios
    .post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      err?.response?.data?.detail && toast.error(err?.response?.data?.detail);
      err?.response?.data?.message && toast.error(err?.response?.data?.message);
      apiErrorResponse(err);
      return err;
    });
}

export async function patch(url, data) {
  const token = localStorage.getItem("helloreddie-token");

  const formData = new FormData();

  Object.keys(data).map((key) => formData.append(key, data[key]));

  return axios
    .patch(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      err?.response?.data?.detail && toast.error(err?.response?.data?.detail);
      err?.response?.data?.message && toast.error(err?.response?.data?.message);
      apiErrorResponse(err);
      return err;
    });
}

export async function del(url) {
  const token = localStorage.getItem("helloreddie-token");

  return axios
    .delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      err?.response?.data?.detail && toast.error(err?.response?.data?.detail);
      err?.response?.data?.message && toast.error(err?.response?.data?.message);
      apiErrorResponse(err);
      return err;
    });
}

export async function put(url, data) {
  const token = localStorage.getItem("helloreddie-token");

  return axios
    .put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Content-Type': 'app'
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      err?.response?.data?.detail && toast.error(err?.response?.data?.detail);
      err?.response?.data?.message && toast.error(err?.response?.data?.message);
      apiErrorResponse(err);
      return err;
    });
}
