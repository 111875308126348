import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const Login = async (params) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login`, params);
    return response; // Assuming the API returns data in the response
  } catch (error) {
    // Handle any errors that occurred during the API call
    console.error("Error in login:", error);
    // You might want to display an error message to the user or take other actions
    throw error; // Rethrow the error to the calling function if needed
  }
};

export const AuthService = {
  Login,
};
