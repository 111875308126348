import AuthSlice from "./slice/AuthSlice";
import UserSlice from "./slice/UserSlice";
import GeneralSlice from "./slice/GeneralSlice";
import SettingSlice from "./slice/SettingSlice";
import PeopleSlice from "./slice/PeopleSlice";

const reducers = {
    general: GeneralSlice.reducer,
    auth: AuthSlice.reducer,
    user: UserSlice.reducer,
    setting: SettingSlice.reducer,
    people: PeopleSlice.reducer,
};

export default reducers;