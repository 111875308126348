import React from "react";
import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

FloatingButton.propTypes = {
  onClick: PropTypes.func,
};

export default function FloatingButton(props) {
  const { id } = useParams();
  const { onClick } = props;
  const path = window.location.pathname;

  return (
    <Fab
      variant="extended"
      color="primary"
      onClick={onClick}
      sx={{ position: "fixed", bottom: 30, right: 20 }}
    >
      <Add sx={{ mr: 1 }} />
      {path === "/app/user" && "Add User"}
      {path === "/app/people" && "Add People"}
      {path === "/app/category" && "Add Category"}
      {path === "/app/subcategory" && "Add SubCategory"}
      {path === "/app/payments" && "Add Plan"}
      {path === `/app/payments/plan/${id}` && "Add subscription"}
      {path === `/app/palncard` && "Paln Card"}
    </Fab>
  );
}
