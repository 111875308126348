import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { post } from "utils/api";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "12px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const API_URL = process.env.REACT_APP_API_URL;

const ModalContent = ({
  open,
  onClose,
  credit,
  id,
  AllUserApi,
}) => {
  const { available_credit } = credit;
  const history = useHistory();
  const { type } = credit;
  console.log(type,"type...")
  const userId = id;
  const [addError, setAddError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [addNewCredit, setAddNewCredit] = useState("");
  const [expiryDate, setExpiryDate] = useState("");

  useEffect(() => {
    setAddNewCredit("");
    setExpiryDate("");
    setAddError("");
    setExpiryDateError("");
  }, [userId, type, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "addNewCredit") {
      const newCreditValue = parseInt(value, 10);
      setAddNewCredit(newCreditValue >= 1 ? newCreditValue : "");
      if (newCreditValue >= 1) {
        setAddError("");
      }
    }
    if (name === "startDate") setStartDate(value);
    if (name === "expiryDate") {
      const selectedDate = new Date(value);
      const today = new Date();
      if (selectedDate < today) {
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setExpiryDate(tomorrow.toISOString().slice(0, 10));
      } else {
        setExpiryDate(value);
        setExpiryDateError("");
      }
    }
  };

  const handleUpdateCredits = async () => {
    try {
      if (addNewCredit <= 0 || expiryDate === "") {
        if (addNewCredit <= 0) {
          setAddError("Credit must be greater than 0");
        }
        if (expiryDate === "") {
          setExpiryDateError("Must select Expiry Date");
        }
        return;
      }

      const data = {
        credit: addNewCredit,
        start_date: new Date(startDate).toISOString(),
        expiry_date: new Date(expiryDate).toISOString(),
        user_id: userId,
        type: type,
      };

      const response = await post(`${API_URL}/admin/credit`, data);
      if (response.status === 200) {
        onClose();
        toast.success("Credit Added Successfully");
        AllUserApi();
        history.push("/app/user");
      } else {
        toast.error("Error adding credit. Please try again later.");
      }
    } catch (error) {
      console.error("Error updating credits:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          Update Credits
        </Typography>
        <TextField
          label="Previous Credits"
          type="number"
          name="availableCredits"
          disabled={true}
          value={available_credit}
          // onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />
        <TextField
          label="Add New Credit"
          type="number"
          name="addNewCredit"
          value={addNewCredit}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
          inputProps={{ min: 1 }} // Set the minimum allowed value to 1
        />
        {addError && (
          <Typography variant="body2" color="error" gutterBottom>
            {addError}
          </Typography>
        )}
        <TextField
          label="Start Date"
          type="date"
          name="startDate"
          value={startDate}
          disabled
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="Expiry Date"
          type="date"
          name="expiryDate"
          value={expiryDate}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            // min: new Date(new Date()+24*60*60*1000).toISOString().slice(0, 10), // Set min attribute to tomorrow's date
            min: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
              .toISOString()
              .slice(0, 10),
          }}
        />
        {expiryDateError && (
          <Typography variant="body2" color="error" gutterBottom>
            {expiryDateError}
          </Typography>
        )}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
          <Button
            onClick={handleUpdateCredits}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalContent;
