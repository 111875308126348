import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import React, { useState } from "react";
const AccountSetting = (props) => {
  const { formik, planData, reddit, setReddit, owner, setOwner } = props;

  return (
    <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Add Subscription
      </Typography>
      {
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Input
              label="Plan Type*"
              type="text"
              disabled={planData ? true : false}
              name="plan"
              value={formik.values.plan}
              onChange={formik.handleChange}
              error={formik.touched.plan && Boolean(formik.errors.plan)}
              helpertext={formik.touched.plan && formik.errors.plan}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Price*"
              type="text"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              multiline
              maxRows={4}
              error={
                Boolean(formik.touched.price) && Boolean(formik.errors.price)
              }
              helpertext={formik.touched.price && formik.errors.price}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputLabel>Period</InputLabel>
            <FormControl fullWidth>
              <Select
                name={`period`}
                value={formik.values.period}
                onChange={formik.handleChange}
              >
                <MenuItem value="MONTH">Monthly</MenuItem>
                <MenuItem value="YEAR">Annually</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Keywords*"
              type="text"
              name="superCredits"
              value={formik.values.superCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.superCredits &&
                Boolean(formik.errors.superCredits)
              }
              helpertext={
                formik.touched.superCredits && formik.errors.superCredits
              }
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Posts*"
              type="text"
              name="basicCredits"
              value={formik.values.basicCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.basicCredits &&
                Boolean(formik.errors.basicCredits)
              }
              helpertext={
                formik.touched.basicCredits && formik.errors.basicCredits
              }
            />
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Reddit Account</Typography>
              <Switch
                checked={reddit}
                name="status"
                onChange={(e) => setReddit(e?.target?.checked)}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Owner Account</Typography>
              <Switch
                checked={owner}
                name="status"
                onChange={(e) => setOwner(e?.target?.checked)}
              />
            </Box>
          </Grid>
        </Grid>
      }
    </Card>
  );
};

export default AccountSetting;
