
// const API_URL = process.env.REACT_APP_API_URL;
// const GetSetting = async () => get(`${API_URL}/setting`);

// const vartype = 11;

// const UpdateSetting = async (params) => patch(`${API_URL}/admin/change-password`, params);
// const UpdateSetting = async (id, params) => post(`${API_URL}/admin/change-password`, params);

export const SettingService = {
    // GetSetting,
    // UpdateSetting
};
