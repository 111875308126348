import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "12px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ActiveCreditModal = ({
  open,
  onClose,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  AllUserApi,
}) => {
  const [errors, setErrors] = useState({
    startDate: "",
    endDate: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setStartDate(value);
      if (value) {
        setErrors((prevErrors) => ({ ...prevErrors, startDate: "" }));
      }
    }
    if (name === "endDate") {
      const selectedDate = new Date(value);
      const today = new Date();
      if (selectedDate > today) {
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setEndDate(tomorrow.toISOString().slice(0, 10));
      } else {
        setEndDate(value);
      }
      if (value) {
        setErrors((prevErrors) => ({ ...prevErrors, endDate: "" }));
      }
    }
  };

  const validate = () => {
    let isValid = true;
    let errors = { startDate: "", endDate: "" };

    if (!startDate) {
      errors.startDate = "Start date is required.";
      isValid = false;
    }
    if (!endDate) {
      errors.endDate = "End date is required.";
      isValid = false;
    } else if (new Date(endDate) <= new Date(startDate)) {
      errors.endDate = "End date must be later than start date.";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" gutterBottom>
          Active Credits
        </Typography>

        <TextField
          label="Start Date"
          type="date"
          name="startDate"
          value={startDate}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.startDate)}
          helperText={errors.startDate}
        />
        <TextField
          label="End Date"
          style={{ marginTop: "22px" }}
          type="date"
          name="endDate"
          value={endDate}
          onChange={handleInputChange}
          fullWidth
          variant="outlined"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.endDate)}
          helperText={errors.endDate}
        />

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button onClick={onClose} variant="contained" color="secondary">
            Close
          </Button>
          <Button
            onClick={() => {
              if (validate()) {
                AllUserApi();
              }
            }}
            variant="contained"
            color="primary"
          >
            Add
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActiveCreditModal;
