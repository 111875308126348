import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Input from "components/Input";

const AccountSettings = (props) => {
  const { formik } = props;

  return (
    <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Add plan Card
      </Typography>
      {
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Input
              label="Total Price*"
              type="text"
              name="totalPrice"
              value={formik.values.totalPrice}
              onChange={formik.handleChange}
              error={
                formik.touched.totalPrice && Boolean(formik.errors.totalPrice)
              }
              helpertext={formik.touched.totalPrice && formik.errors.totalPrice}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Plan Type*"
              type="text"
              name="planType"
              placeholder="Ex : basic , premium , professional"
              value={formik.values.planType}
              onChange={formik.handleChange}
              multiline
              maxRows={4}
              error={
                Boolean(formik.touched.planType) &&
                Boolean(formik.errors.planType)
              }
              helpertext={formik.touched.planType && formik.errors.planType}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputLabel>Period</InputLabel>
            <FormControl fullWidth>
              <Select
                name={`period`}
                value={formik.values.period}
                onChange={formik.handleChange}
              >
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="annually">Annually</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Super Credits*"
              type="text"
              name="superCredits"
              value={formik.values.superCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.superCredits &&
                Boolean(formik.errors.superCredits)
              }
              helpertext={
                formik.touched.superCredits && formik.errors.superCredits
              }
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Basic Credits*"
              type="text"
              name="basicCredits"
              value={formik.values.basicCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.basicCredits &&
                Boolean(formik.errors.basicCredits)
              }
              helpertext={
                formik.touched.basicCredits && formik.errors.basicCredits
              }
            />
          </Grid>

          {/* <Grid item lg={6} xs={12}>
            <Input
              label="Creative Credits*"
              type="text"
              name="creativeCredits"
              value={formik.values.creativeCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.creativeCredits &&
                Boolean(formik.errors.creativeCredits)
              }
              helpertext={
                formik.touched.creativeCredits && formik.errors.creativeCredits
              }
            />
          </Grid> */}

          <Grid item lg={6} xs={12}>
            <Input
              label="Search Credits*"
              type="text"
              name="searchCredits"
              value={formik.values.searchCredits}
              onChange={formik.handleChange}
              error={
                formik.touched.searchCredits &&
                Boolean(formik.errors.searchCredits)
              }
              helpertext={
                formik.touched.searchCredits && formik.errors.searchCredits
              }
            />
          </Grid>
        </Grid>
      }
    </Card>
  );
};

export default AccountSettings;
