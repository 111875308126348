import { all, call, put, takeLatest } from "redux-saga/effects";
import { PeopleService } from "../services";
// import {AuthActions, GeneralActions, PeopleActions} from "../slices/actions";
import { GeneralActions, PeopleActions } from "../slices/actions";

function *getPeoples({ type, payload }) {
  try {
    yield put(GeneralActions.removeError(type));
    yield put(GeneralActions.startLoading(type));
    // const result = yield call(PeopleService.GetPeoples, payload);
    const result = yield call(PeopleService.GetPeopleList, payload);

    // yield put(PeopleActions.getPeoplesSuccess(result.data));
    yield put(PeopleActions.getPeoplesSuccess({
      data: result.data,
      current_page: payload.page,
      total_pages: result.total_pages,
      total_results: result.total_results,
      per_page: result.per_page,
    }));
    yield put(GeneralActions.stopLoading(type));
  } catch (err) {
    yield put(GeneralActions.stopLoading(type));
    yield put(
      GeneralActions.addError({
        action: type,
        message: err.response?.data?.error,
      })
    );
  }
}

function *getPeopleById({ type, payload }) {
  try {
    yield put(GeneralActions.removeError(type));
    yield put(GeneralActions.startLoading(type));

    const result = yield call(PeopleService.GetPeopleById, payload);
    yield put(PeopleActions.getPeopleByIdSuccess(result.data));
    yield put(GeneralActions.stopLoading(type));
  } catch (err) {
    yield put(GeneralActions.stopLoading(type));
    yield put(
      GeneralActions.addError({
        action: type,
        message: err.response?.data?.error,
      })
    );
  }
}

function *createPeople({ type, payload }) {
  try {
    yield put(GeneralActions.removeError(type));
    yield put(GeneralActions.startLoading(type));

    // payload.status = payload.status ? 1 : 0;

    if (payload.country) {
      payload.country = payload.country.name;
    }
    const result = yield call(PeopleService.CreatePeople, payload);

    yield put(
      GeneralActions.addSuccess({
        action: type,
        message: result.data.message,
      })
    );
    yield put(GeneralActions.stopLoading(type));
  } catch (err) {
    yield put(GeneralActions.stopLoading(type));
    yield put(
      GeneralActions.addError({
        action: type,
        message: err.response?.data?.error,
      })
    );
  }
}

function *updatePeople({ type, payload }) {
  try {
    yield put(GeneralActions.removeError(type));
    yield put(GeneralActions.startLoading(type));

    if (!(payload.avatar instanceof File)) {
      delete payload.avatar;
    }

    payload.status = payload.status ? 1 : 0;

    if (payload.country) {
      payload.country = payload.country.name;
    }

    const result = yield call(PeopleService.UpdatePeople, payload.id, payload);

    yield put(
      GeneralActions.addSuccess({
        action: type,
        message: result.data.message,
      })
    );
    yield put(GeneralActions.stopLoading(type));
  } catch (err) {
    yield put(GeneralActions.stopLoading(type));
    yield put(
      GeneralActions.addError({
        action: type,
        message: err.response?.data?.error,
      })
    );
  }
}

function *deletePeople({ type, payload }) {
  try {
    yield put(GeneralActions.removeError(type));
    yield put(GeneralActions.startLoading(type));

    const result = yield call(PeopleService.DeletePeople, payload);

    yield put(
      GeneralActions.addSuccess({
        action: type,
        message: result.data.message,
      })
    );
    yield put(GeneralActions.stopLoading(type));
  } catch (err) {
    yield put(GeneralActions.stopLoading(type));
    yield put(
      GeneralActions.addError({
        action: type,
        message: err.response?.data?.error,
      })
    );
  }
}

function *profilePeople({ type }) {
  try {
    yield put(GeneralActions.removeError(type));
    yield put(GeneralActions.startLoading(type));

    const result = yield call(PeopleService.Profile);

    if (result) {
      yield put(PeopleActions.profilePeopleSuccess(result.data));
    }

    yield put(GeneralActions.stopLoading(type));
  } catch (err) {
    yield put(GeneralActions.stopLoading(type));
    // yield put(AuthActions.logout());
  }
}

export function *PeopleWatcher() {
  yield all([
    yield takeLatest(PeopleActions.getPeoples.type, getPeoples),
    yield takeLatest(PeopleActions.getPeopleById.type, getPeopleById),
    yield takeLatest(PeopleActions.createPeople.type, createPeople),
    yield takeLatest(PeopleActions.updatePeople.type, updatePeople),
    yield takeLatest(PeopleActions.deletePeople.type, deletePeople),
    yield takeLatest(PeopleActions.profilePeople.type, profilePeople),
  ]);
}
