import {
  Box,
  Button,
  Card,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import React, { useCallback, useEffect, useState } from "react";
import { get } from "utils/api";
import styled from "@emotion/styled";
import ListSkeleton from "components/Skeleton/ListSkeleton";

const API_URL = process.env.REACT_APP_API_URL;

const FilterBox = styled(Box)(() => ({
  width: "100%",
  marginTop: 30,
  marginBottom: 20,
  display: "flex",
  justifyContent: "space-between",
}));

const PaymentList = () => {
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const totalCount = responseData?.total_results || 0;
  const pageCount = Math.ceil(totalCount / 10);
  const [filter, setFilter] = useState({
    keyword: "",
    page: 1,
  });

  const getPaymentList = useCallback(async () => {
    setLoading(true);
    const response = await get(
      `${API_URL}/admin/subscription?page=${filter?.page}&per_page=10`
    );
    setResponseData(response?.data);
    setLoading(false);
  }, [filter?.page]);

  useEffect(() => {
    getPaymentList();
  }, [filter, getPaymentList]);

  const getRevoke = async (id) => {
    try {
      setLoading(true);
      //next will be used
      const response = await get(`${API_URL}/admin/subscription-credit/${id}`);
      setLoading(false);
      getPaymentList();
    } catch (error) {
      console.log("An error occurred while revoking:", error);
      setLoading(false);
    }
  };

  const handleChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFilter({
      ...filter,
      [name]: value,
      page: 1,
    });
  };

  const handleChangePagination = (e, page) => {
    setFilter({
      ...filter,
      page: page,
    });
  };

  return (
    <Card>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Payment List
      </Typography>

      <FilterBox>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item lg={6} sm={12} xs={12}>
            <Input
              label="Search"
              name="keyword"
              placeholder="Search"
              value={filter.keyword ?? ""}
              onChange={handleChangeFilter}
            />
          </Grid>
        </Grid>
      </FilterBox>

      <Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Revoke </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responseData?.total_results === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No Data
                </TableCell>
              </TableRow>
            )}
            {loading ? (
              <ListSkeleton />
            ) : (
              responseData?.data?.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {item?.content?.subscription?.shipping_address
                      ?.first_name || "-"}
                  </TableCell>
                  <TableCell>
                    {item?.content?.subscription?.shipping_address?.last_name ||
                      "-"}
                  </TableCell>
                  <TableCell>{item?.event_type || "-"}</TableCell>
                  <TableCell>
                    {item?.content?.transaction?.amount || "-"}
                  </TableCell>
                  <TableCell>
                    {!item?.user_id && (
                      <Button
                        style={{ width: "100%", height: "40px" }}
                        component="label"
                        variant="contained"
                        onClick={() => {
                          getRevoke(item?.content?.subscription?.id);
                        }}
                      >
                        Revoke
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <Pagination
          sx={{ mt: 1 }}
          page={filter?.page}
          count={pageCount}
          onChange={handleChangePagination}
        />
      </Box>
    </Card>
  );
};

export default PaymentList;
