import React, { useEffect } from "react";
import { Box, Button, Card, Grid } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Input from "../../components/Input";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { GeneralActions, SettingActions } from "../../slices/actions";
import { toast } from "react-toastify";
import { GeneralSelector } from "../../selectors";
import FormSkeleton from "../../components/Skeleton/FormSkeleton";
import { post } from "utils/api";
import * as yup from "yup";

const API_URL = process.env.REACT_APP_API_URL;
export default function Setting() {
    const dispatch = useDispatch();

    const loading = useSelector(GeneralSelector.loader(SettingActions.getSetting.type));


    const success = useSelector(GeneralSelector.success(SettingActions.updateSetting.type));

    useEffect(() => {
        dispatch(SettingActions.getSetting());
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            toast.success(`${success?.message ?? "Password Updated Successfully"}`, {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: false
            });

            dispatch(GeneralActions.removeSuccess(SettingActions.updateSetting.type));
            dispatch(SettingActions.getSetting());
        }
    }, [success, dispatch]);
    const validationSchema = yup.object({
        password: yup.string().required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });


    const handleSubmit = async (values) => {
        try {
            const params = {
                password: values.password,
            };

            // Make the API call to change the password
            const response = await post(`${API_URL}/admin/change-password`, params);

            if (response.status === 200) {
                // If the request is successful (status code 200), show a success toast
                toast.success("Password Changed Successfully");
                formik.resetForm(); // Reset the form to clear the input field after successful submission

            } else {
                // If there's an error in the API response, show an error toast with the response data message
                toast.error(response?.data || "Failed to change password. Please try again later.");
            }
        } catch (error) {
            // Handle errors if the API call fails
            toast.error(error.response?.data?.data || "An error occurred. Please try again later.");
        }
    };

    return (
        <Box>
            <PageTitle title='Manage Admin Password' />

            <Card>
                {loading ? (
                    <FormSkeleton />
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item lg={6} sm={12} xs={12}>
                                <Input
                                    label='Password'
                                    name='password'
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helpertext={formik.touched.password && formik.errors.password} />
                            </Grid>

                            <Grid item lg={12} sm={12} xs={12} container justifyContent='flex-start'>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Card>
        </Box>
    )
}