import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const Model = ({ open, setOpen, handleDelete, deleteId, loading }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle color="error" id="alert-dialog-title">
        {"Delete Plan"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this product? All record related to
          this product will be deleted as well. This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          color="error"
          onClick={() => handleDelete(deleteId)}
          autoFocus
          variant="contained"
        >
          {!loading ? (
            "Delete"
          ) : (
            <CircularProgress style={{ color: "white" }} size={25} />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Model;
