import { createSlice } from "@reduxjs/toolkit";

export const PeopleSlice = createSlice({
  name: "people",
  initialState: {
    profile: null,
    peoples: [],
    pagination: {
      currentPage: 1,
      totalPages: 1,
      totalResults: 0,
      perPage: 10,
    },
    people: {},
  },
  reducers: {
    profilePeople: () => {},
    profilePeopleSuccess: (state, action) => {
      state.profile = action.payload;
    },
    resetProfile: (state) => {
      state.profile = null;
    },
    getPeoples: () => {},
    getPeopleList: () => {},
    getPeoplesSuccess: (state, action) => {
      state.people = {};
      state.peoples = action.payload.data;
      state.pagination = {
        currentPage: action.payload.current_page,
        totalPages: action.payload.total_pages,
        totalResults: action.payload.total_results,
        perPage: action.payload.per_page,
      };
    },
    getPeopleById: () => {},
    getPeopleByIdSuccess: (state, action) => {
      state.people = action.payload;
    },
    createPeople: () => {},
    updatePeople: () => {
      
    },
    deletePeople: () => {},
  },
});

export default PeopleSlice;
