import React from "react";
import { Box } from "@mui/material";
import PageTitle from "components/PageTitle";
import Widgets from "./components/Widgets";

export default function Dashboard() {
  return (
    <Box>
      <PageTitle title="Dashboard" />
      <Widgets />
    </Box>
  );
}
