import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import * as yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AccountSetting from "./components/Create/AccountSetting";
import { get, post } from "utils/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const API_URL = process.env.REACT_APP_API_URL;

export default function CreateUser() {
  const history = useHistory();
  const location = useLocation();
  const user_type = location?.state?.state?.user_type;
  console.log(user_type, "user_type....");
  const [userData, setuserData] = useState();
  const [loading, setLoading] = useState(false);
  const [ispeopleshow, setIsPeopleShow] = useState();
  const [iscreativeAiShow, setIsCreativeAiShow] = useState();
  const [isNexusShow, setIsNexusShow] = useState();
  const { id } = useParams();
  console.log(userData, "userData,,,,");
  const handleUserData = (id) => {
    setLoading(true);

    if (id) {
      get(`${API_URL}/admin/get-user-data?id=${id}`)
        .then((data) => {
          setuserData(data?.data?.data);
          setIsPeopleShow(data?.data?.data?.is_people_show);
          setIsCreativeAiShow(data?.data?.data?.is_creative_ai_show);
          setIsNexusShow(data?.data?.data?.is_nexus);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleUserData(id);
    // eslint-disable-next-line
  }, [id]);

  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    name: yup.string().required("Name is required"),
    // company_name: yup.string().required('Company Name is required'),
    password: yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: userData ? userData.email : "",
      password: "",
      user_type: "",
      name: userData ? userData.name : "",
      company_name: userData ? userData.company_name : "",
    },

    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const params = {
      email: values.email,
      password: values.password,
      name: values.name,
      company_name: values.company_name || "",
      is_people_show: ispeopleshow || "false",
      is_creative_ai_show: iscreativeAiShow || "false",
      is_nexus: isNexusShow || false,
    };
    const newPassword = {
      id: id,
      password: values.password,
      user_type: user_type,
    };

    try {
      if (id) {
        // If "id" exists, it means we are updating the user
        const response = await post(
          `${API_URL}/admin/change-password-user`,
          newPassword
        );
        if (response.status === 200) {
          // If the request is successful (status code 200), show a success toast
          toast.success(response.data.data || "Password1 Updated Successfully");
          history.push("/app/user");
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data ||
              "Failed to update password. Please try again later."
          );
        }
      } else {
        // If "id" does not exist, it means we are creating a new user
        const response = await post(`${API_URL}/admin/add-user`, params);
        if (response.status === 200) {
          // If the request is successful (status code 200), show a success toast
          toast.success("User Added Successfully");
          history.push("/app/user");
        } else {
          // If there's an error in the API response, show an error toast with the response data message
          toast.error(
            response?.data || "Failed to add user. Please try again later."
          );
        }
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
    }
  };

  return (
    <Box>
      <PageTitle title={id ? "Update User" : "Create User"} />

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1}>
          <Grid item lg={12} sm={12} xs={12}>
            <AccountSetting
              loading={loading}
              formik={formik}
              is_people_show={ispeopleshow}
              user_type={user_type}
              setIsPeopleShow={setIsPeopleShow}
              is_creative_ai_show={iscreativeAiShow}
              setIsCreativeAiShow={setIsCreativeAiShow}
              setIsNexusShow={setIsNexusShow}
              is_nexus={isNexusShow}
            />
            <Grid item lg={12} sm={12} xs={12} container gap={5}>
              <Grid
                item
                lg={2}
                sm={2}
                xs={2}
                container
                justifyContent="flex-end"
              >
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push("/app/user");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                lg={2}
                sm={2}
                xs={2}
                container
                justifyContent="flex-end"
              >
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
