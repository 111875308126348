import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import FloatingButton from "components/FloatingButton";
import PageTitle from "components/PageTitle";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { del, get } from "utils/api";
const API_URL = process.env.REACT_APP_API_URL;

const PlanCard = () => {
  const history = useHistory();
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDelete = async (id) => {
    setLoading(true);
    const res = await del(`${API_URL}/admin/plan-card/${id}`);
    setLoading(false);
    setOpen(false);
    // toast.success(res?.data?.message)
    getPayments();
  };
  const getPayments = async () => {
    setLoading(true);
    const response = await get(`${API_URL}/admin/plan-cards`);
    setResponseData(response?.data?.data);
    setLoading(false);
  };

  useEffect(() => {
    getPayments();
  }, []);

  const handleClick = (id) => {
    // Pass the ID as state when navigating
    history.push(`/app/palncard/create/${id}`);
  };

  return (
    <>
      <div style={{ marginLeft: "20px" }}>
        <PageTitle title="Plan" />
      </div>
      <Grid container spacing={1}>
        {loading ? (
          <div style={{ margin: "auto" }}>
            <CircularProgress size={25} />
          </div>
        ) : responseData?.length !== 0 ? (
          <>
            {responseData?.map((item, idx) => {
              return (
                <Grid key={idx} item xs={12} md={6} lg={3}>
                  <Card
                    style={{
                      height: "340px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <h3 style={{ textAlign: "center" }}>
                        € {item?.total_price}/
                        <sub
                          style={{
                            fontSize: "13px",
                            whiteSpace: "nowrap",
                            color: "#667085",
                          }}
                        >
                          {item?.billed_period}
                        </sub>
                      </h3>

                      <p
                        style={{
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.plan_type}
                      </p>

                      <ul>
                        <li>{item?.super_credits} Super Credits</li>
                        <li>{item?.search_credits} Search Credits</li>
                        <li>{item?.basic_credit} Basic Credits</li>
                        {item?.linkedin_extension && (
                          <li>LinkedIn Extension</li>
                        )}
                        {item?.crm_integration && <li>CRM Integration</li>}
                      </ul>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "auto",
                      }}
                    >
                      <Button
                        onClick={() => handleClick(item?._id)}
                        style={{ width: "100%", height: "40px" }}
                        component="label"
                        variant="contained"
                      >
                        Update
                      </Button>
                      <Button
                        onClick={() => {
                          setDeleteId(item?._id);
                          setOpen(true);
                        }}
                        color="error"
                        style={{ width: "100%", height: "40px" }}
                        component="label"
                        variant="contained"
                      >
                        Delete
                      </Button>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                height: "100%",
                alignItems: "center",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: "20px",
              }}
            >
              <div>
                <img src="https://elstar.themenate.net/img/others/img-2.png" alt="No Data Illustration" />
                <h1>No Plan Card Data</h1>
              </div>
            </div>
            <br />
          </>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle color="error" id="alert-dialog-title">
          {"Delete Plan"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product? All record related to
            this product will be deleted as well. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => handleDelete(deleteId)}
            autoFocus
            variant="contained"
          >
            {!loading ? (
              "Delete"
            ) : (
              <CircularProgress style={{ color: "white" }} size={25} />
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <FloatingButton onClick={() => history.push("/app/palncard/create")} />
    </>
  );
};

export default PlanCard;
