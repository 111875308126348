import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import AccountSetting from "./AccountSetting";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import PageTitle from "components/PageTitle";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import { get, post, put } from "utils/api";
import { toast } from "react-toastify";
const API_URL = process.env.REACT_APP_API_URL;

const SubScriptionForm = () => {
  const history = useHistory();
  // Get access to the parameters from the URL
  const { id } = useParams();
  const location = useLocation();
  const itemId = location.state?.itemId;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [planData, setPlanData] = useState("");
  const [reddit, setReddit] = useState(false)
const [owner, setOwner] = useState(false)

  const validationSchema = Yup.object({
    price: Yup.string()
      .required("Price is required")
      .min(0, "Price must be a positive number"),
    period: Yup.string().required("Period is required"),
    superCredits: Yup.number()
      .required("Super Credits is required")
      .min(0, "Super Credits must be a positive number"),
    basicCredits: Yup.number()
      .required("Basic Credits is required")
      .min(0, "Basic Credits must be a positive number"),
    // creativeCredits: Yup.number()
    //   .required("Creative Credits is required")
    //   .min(0, "Creative Credits must be a positive number"),
    searchCredits: Yup.number()
      .required("Search Credits is required")
      .min(0, "Search Credits must be a positive number"),
  });

  const getPricingSingle = useCallback(async () => {
    const response = await get(`${API_URL}/admin/plan-price-details/${itemId}`);
    setData(response?.data?.data);
  }, [itemId]); // itemId as a dependency

  const getPlanDetails = useCallback(async () => {
    const response = await get(`${API_URL}/admin/plan-details/${id}`);
    setPlanData(response?.data?.data);
  }, [id]); // id as a dependency

  useEffect(() => {
    if (itemId) {
      getPricingSingle();
    }
    getPlanDetails();
  }, [getPricingSingle, getPlanDetails, itemId]); // Add the dependencies here

  const formik = useFormik({
    initialValues: {
      plan: data?.plan_type || planData?.name,
      price: data?.price || "",
      period: data?.period_unit || "MONTH",
      superCredits: data?.super_credits || "",
      basicCredits: data?.basic_credits || "",
      
    },

    enableReinitialize: true,
    validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: (values) => {
      itemId ? handleUpdate(values) : handleSubmit(values);
    },
  });
  const handleSubmit = async (values) => {
    setLoading(true);

    const params = {
      plan_type: values?.plan.toLowerCase(),
      price: values?.price,
      period: values?.period,
      plan_id: id,
      posts: values?.superCredits,
      keywords: values?.basicCredits,
      is_owner : owner,
      is_reddie : reddit,
    };

    try {
      // If "id" does not exist, it means we are creating a new user
      const response = await post(`${API_URL}/admin/plan-price `, params);
      if (response.status === 200) {
        // If the request is successful (status code 200), show a success toast
        toast.success(response?.data?.message);
        setLoading(false);
        history.push(`/app/payments/plan/${id}`);
      } else {
        toast.error(
          response?.data?.message ||
            "Failed to add plan Please try again later."
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
      setLoading(false);
    }
  };
  const handleUpdate = async (values) => {
    setLoading(true);
    const updatedFields = {};
    updatedFields.plan_id = id;
    updatedFields.price_id = data?._id;
    updatedFields.price = values.price;
    updatedFields.plan_type = values.plan.toLowerCase();
    updatedFields.period = values.period;

    if (values.superCredits !== formik.initialValues.superCredits) {
      updatedFields.super_credits = values.superCredits;
    }

    if (values.basicCredits !== formik.initialValues.basicCredits) {
      updatedFields.basic_credits = values.basicCredits;
    }

    // if (values.creativeCredits !== formik.initialValues.creativeCredits) {
    //   updatedFields.creative_credits = values.creativeCredits;
    // }

    if (values.searchCredits !== formik.initialValues.searchCredits) {
      updatedFields.search_credits = values.searchCredits;
    }

    if (values.linkedin_extension !== formik.initialValues.linkedin_extension) {
      updatedFields.linkedin_extension = values.linkedin_extension;
    }

    if (values.crm_integration !== formik.initialValues.crm_integration) {
      updatedFields.crm_integration = values.crm_integration;
    }

    if (values.is_visible !== formik.initialValues.is_visible) {
      updatedFields.is_visible = values.is_visible;
    }

    try {
      // If "id" does not exist, it means we are creating a new user
      const response = await put(`${API_URL}/admin/plan-price `, updatedFields);
      if (response.status === 200) {
        setData(response?.data?.data);
        // If the request is successful (status code 200), show a success toast
        toast.success(response?.data?.message);
        setLoading(false);
        history.push(`/app/payments/plan/${id}`);
      } else {
        // If there's an error in the API response, show an error toast with the response data message
        toast.error(
          response?.data?.message ||
            "Failed to add plan Please try again later."
        );
        setLoading(false);
      }
    } catch (error) {
      // Handle errors if the API call fails
      toast.error(
        error.response.data.data
          ? error.response.data.data
          : "An error occurred. Please try again later."
      );
      setLoading(false);
    }
  };

  return (
    <div>
      <Box>
        <PageTitle title=" Add Subscription" />

        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item lg={12} sm={12} xs={12}>
              <AccountSetting formik={formik} planData={planData?.name} reddit={reddit} setReddit={setReddit} owner={owner} setOwner={setOwner}/>

              <Grid item lg={12} sm={12} xs={12} container gap={5}>
                <Grid item lg={2} sm={2} xs={2} container>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push(`/app/payments/plan/${id}`);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item lg={2} sm={2} xs={2} container>
                  <Button
                    fullWidth
                    // disabled={loading}
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {!loading ? (
                      "Submit"
                    ) : (
                      <CircularProgress style={{ color: "white" }} size={25} />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default SubScriptionForm;
