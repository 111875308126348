import React, { useState } from "react";
import {
  Card,
  // Chip,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  // MenuItem,
  Typography,
} from "@mui/material";
// import Box from "@mui/material/Box";
import Input from "components/Input";
// import SelectField from "components/SelectField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { put } from "utils/api";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const API_URL = process.env.REACT_APP_API_URL;

AccountSetting.propTypes = {
  formik: PropTypes.object,
};

export default function AccountSetting(props) {
  const {
    formik,
    setIsPeopleShow,
    setIsCreativeAiShow,
    loading,
    is_people_show,
    is_creative_ai_show,
    user_type,
    setIsNexusShow,
    is_nexus,
  } = props;
  const { id } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleToggleStatus = (e) => {
    const { checked } = e.target;
    if (id !== undefined) {
      const data = {
        user_id: id.toString(),
        is_show: checked.toString(),
        type: "people",
      };
      put(`${API_URL}/admin/`, data)
        .then((response) => {
          setIsPeopleShow(checked);
          toast.success("Updated Successfully...");
        })
        .catch((error) => {
          console.error("PUT request error", error);
        });
    } else {
      setIsPeopleShow(checked);
    }
  };
  const handleCreativeAi = (e) => {
    const { checked } = e.target;
    if (id !== undefined) {
      const data = {
        user_id: id.toString(),
        is_show: checked.toString(),
        type: "creative_ai",
      };
      put(`${API_URL}/admin/`, data)
        .then((response) => {
          setIsCreativeAiShow(checked);
          toast.success("Updated Successfully...");
        })
        .catch((error) => {
          console.error("PUT request error", error);
        });
    } else {
      setIsCreativeAiShow(checked);
    }
  };

  const handleNexus = (e) => {
    const { checked } = e.target;
    if (id !== undefined) {
      const data = {
        user_id: id.toString(),
        is_show: checked.toString(),
        type: "nexus",
      };
      put(`${API_URL}/admin/`, data)
        .then((response) => {
          setIsNexusShow(checked);
          toast.success("Updated Successfully...");
        })
        .catch((error) => {
          console.error("PUT request error", error);
        });
    } else {
      setIsNexusShow(checked);
    }
  };

  return (
    <Card sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        {id ? "Update User" : "Add User"}
      </Typography>
      {!loading && (
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Input
              label="Name*"
              type="text"
              name="name"
              value={formik.values.name}
              disabled={id ? true : false}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.name) && Boolean(formik.errors.name)
              }
              helpertext={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              label="Email*"
              type="email"
              name="email"
              value={formik.values.email}
              disabled={id ? true : false}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.email) && Boolean(formik.errors.email)
              }
              helpertext={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              label="Company Name"
              type="text"
              name="company_name"
              disabled={id ? true : false}
              value={formik.values.company_name}
              onChange={formik.handleChange}
              error={
                Boolean(formik.touched.company_name) &&
                Boolean(formik.errors.company_name)
              }
              helpertext={
                formik.touched.company_name && formik.errors.company_name
              }
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              label="Password*"
              inputProps={{
                autoComplete: "new-password",
              }}
              placeholder="●●●●●"
              type={showPassword ? "text" : "password"}
              name="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helpertext={formik.touched.password && formik.errors.password}
              disabled={user_type !== "email" && user_type !== "app_sumo" && user_type !== "" && user_type !== undefined}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Show People</Typography>
              <Switch
                checked={is_people_show || false}
                name="status"
                onChange={handleToggleStatus}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Creative AI</Typography>
              <Switch
                checked={is_creative_ai_show || false}
                name="status"
                onChange={handleCreativeAi}
              />
            </Box>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Box
              sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Nexus</Typography>
              <Switch
                checked={is_nexus || false}
                name="status"
                onChange={handleNexus}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {loading && (
        <div
          className=""
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      )}
    </Card>
  );
}
