import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "utils/api";
import FloatingButton from "components/FloatingButton";
import PageTitle from "components/PageTitle";
import NoData from "./NoData";

const API_URL = process.env.REACT_APP_API_URL;

const Payments = () => {
  const history = useHistory();
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPayments = async () => {
    setLoading(true);
    const response = await get(`${API_URL}/admin/plan`);
    setResponseData(response?.data?.data);
    setLoading(false);
  };
  useEffect(() => {
    getPayments();
  }, []);

  const handlePlan = (id, name) => {
    history.push(`/app/payments/plan/${id}`, { plantype: name });
  };

  return (
    <>
      <div style={{ marginLeft: "20px" }}>
        <PageTitle title="Plan" />
      </div>
      <Grid container spacing={1}>
        {loading ? (
          <div style={{ margin: "auto" }}>
            <CircularProgress size={25} />
          </div>
        ) : responseData?.length !== 0 ? (
          responseData?.map((item, idx) => (
            <Grid item key={idx} lg={3} md={6} sm={12}>
              <div
                onClick={() => handlePlan(item?._id)}
                style={{
                  height: "",
                  backgroundColor: "#FFE6DD",
                  border: "2px solid #FF5B1F",
                  color: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  margin: "20px",
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <div>
                  <h2
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      background: "#FF5B1F",
                      borderRadius: "5px",
                      color: "#fff",
                    }}
                  >
                    {item?.name}
                  </h2>
                  <div
                    style={{
                      marginTop: "5px",
                      height: "",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 6,
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        color: "#000",
                        margin: "0px",
                      }}
                    >
                      {item?.description}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#FF5B1F",
                    fontWeight: "bold",
                    marginTop: "25px",
                    cursor: "pointer",
                    paddingTop: "25px",
                  }}
                  onClick={() => handlePlan(item?._id, item?.name)}
                >
                  Choose Plan
                </div>
              </div>
            </Grid>
          ))
        ) : (
          <>
            <NoData />
          </>
        )}
      </Grid>
      <div
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: "#4CAF50",
          borderRadius: "5px",
          color: "#fff",
          position: "absolute",
          bottom: "30px",
          right: "30px",
        }}
      >
        <FloatingButton onClick={() => history.push("/app/payments/create")} />
      </div>
    </>
  );
};

export default Payments;
