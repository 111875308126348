import {
  Card,
  Grid,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import React from "react";

const AccountSetting = (props) => {
  const { formik } = props;
  return (
    <Card style={{ position: "relative" }} sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Add plan
      </Typography>
      {
        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Input
              label="Name*"
              type="text"
              name="name"
              placeholder="Ex : basic , premium , professional"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helpertext={formik.touched.name && formik.errors.name}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <Input
              label="Description*"
              type="text"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              multiline
              maxRows={4}
              error={
                Boolean(formik.touched.description) &&
                Boolean(formik.errors.description)
              }
              helpertext={
                formik.touched.description && formik.errors.description
              }
            />
          </Grid>
        </Grid>
      }
    </Card>
  );
};

export default AccountSetting;
