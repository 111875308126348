import {all, fork} from 'redux-saga/effects'
import {AuthWatcher} from "./AuthSaga";
import {UserWatcher} from "./UserSaga";
import {SettingWatcher} from "./SettingSaga";
import { PeopleWatcher } from './PeopleSaga';

export default function *rootSaga() {
    yield all([
        AuthWatcher,
        UserWatcher,
        SettingWatcher,
        PeopleWatcher
    ].map(fork));
}